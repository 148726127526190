<template>
  <div class="edit-table">
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
    <vxe-button  v-if="code !=='view'" @click="addActivity" style="margin-bottom: 10px">活动类型</vxe-button>

    <div v-for="(item, k) in detailVos" :key="k" class="list-wrap">
      <el-form
        :model="detailForm"
        :rules="detailRule"
        ref="detailForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="活动大类" prop="name">
              <div class="cost-select" @click="openHandleClick('categories', 'big_category_list', 'categoriesCode', k, null,{enableStatus: '009',actType: 'promotion',controlIds:controlIds})">
                <el-input :readonly="true" v-model="item.categoriesName" placeholder="活动大类"></el-input>
                <div class="clear-icon" v-if="code !=='view'">
                  <i class="el-icon-circle-close" @click="clearFn($event,'categories',k,null)"></i>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="活动细类" prop="fineName">
              <div class="cost-select" @click="openHandleClick('fine', 'activity_category_list', 'fineCode', k, null,{categoriesCode:item.categoriesCode,actType: 'promotion'})">
                <el-input :readonly="true" v-model="item.fineName" placeholder="活动细类"></el-input>
                <div class="clear-icon" v-if="code !=='view'">
                  <i class="el-icon-circle-close" @click="clearFn($event,'fine',k,null)"></i>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col  v-if="code!=='view'" :span="8" style="display:flex; justify-content: flex-end;align-items: center;">
            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteActivity(k)"></el-button>
          </el-col>
        </el-row>
        <vxe-button v-if="code!=='view'" @click="addDetail(k,item)" style="margin-bottom: 10px"
          >新增明细</vxe-button>

        <el-tabs  v-model="item.editableTabsValue"  type="card" :closable="code=='view'?false:true" @tab-remove="removeTab($event,k)">
          <el-tab-pane
            :key="act.tabValue"
            v-for="(act, j) in item.activityInfo"
            :label="'活动明细表单'+(j+1)"
            :name="act.tabValue"
          >
            <el-row>
              <el-col :span="8">
                <el-form-item label="关联预算" prop="ext61">
                  <el-select
                   :disabled="code==='view'"
                    v-model="act.ext61"
                    placeholder="请选择关联预算"
                    @change="changeFeeBudget($event,k,j)"
                    @focus="getCategoriesCode(item.categoriesCode,k)"
                  >
                    <el-option
                      v-for="(item,bIndex) in item.budgetList"
                      :key="bIndex"
                      :label="item.feeBudgetCode"
                      :value="item.feeBudgetCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="公司主体" prop="ext3">
                  <el-input
                    disabled
                    v-model="act.ext3"
                    placeholder="公司主体"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="预算科目" prop="ext19">
                  <el-input
                    disabled
                    v-model="act.ext19"
                    placeholder="预算科目"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 活动明细信息 -->
            <el-row>
              <el-col :span="8">
                <el-form-item label="活动明细名称" prop="ext1">
                  <el-input
                   :disabled="code==='view'"
                    v-model="act.ext1"
                    placeholder="活动明细名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="活动明细编码" prop="actDetailCode">
                  <el-input
                    disabled
                    v-model="act.actDetailCode"
                    placeholder="活动明细编码"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="活动执行时间" prop="name">
                  <el-date-picker
                   :disabled="code==='view'"
                    v-model="act.executeTime"
                    @change="changeExecuteTime($event,k,j)"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="受益经销商" prop="ext7">
                  <div class="cost-select" :style="disableExt7?'pointer-events: none':''"   @click="openHandleClick('customer', 'customer_list', 'customerCode', k, j,null)">
                    <el-input v-model="act.ext7" :readonly="true"   placeholder="受益经销商"></el-input>
                    <div class="clear-icon" v-if="code !=='view'">
                      <i class="el-icon-circle-close" @click="clearFn($event,'customer',k,j)"></i>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="经销商组织" prop="ext67">
                  <div class="cost-select" :style="!act.ext7?'pointer-events: none':''"  @click="openHandleClick('dealer', 'dealer_organization_list', 'orgCode', k, j,{customerCode:act.ext6})">
                    <el-input :readonly="true" v-model="act.ext67" placeholder="经销商组织"></el-input>
                    <div class="clear-icon" v-if="code !=='view'">
                      <i class="el-icon-circle-close" @click="clearFn($event,'dealer',k,j)"></i>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="经销商等级" prop="ext75">
                  <el-select
                    v-model="act.ext75"
                    placeholder="经销商等级"
                    disabled
                  >
                    <el-option
                      v-for="lItem in levelList"
                      :key="lItem.dictCode"
                      :label="lItem.dictValue"
                      :value="lItem.dictCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="受益组织" prop="ext5">
                  <div class="cost-select" :style="disableExt7?'pointer-events: none':''"   @click="openHandleClick('org', 'org_list', 'orgCode', k, j,null)">
                    <el-input :readonly="true" v-model="act.ext5" placeholder="受益组织"></el-input>
                    <div class="clear-icon" v-if="code !=='view'">
                      <i class="el-icon-circle-close" @click="clearFn($event,'org',k,j)"></i>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="责任组织" prop="createOrgName">
                  <el-input v-model="act.createOrgName" disabled placeholder="责任组织"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="负责人" prop="ext40">
                  <div class="cost-select" @click="openHandleClick('person', 'person_list', 'userName', k, j,null)">
                    <el-input :readonly="true" v-model="act.ext40" placeholder="负责人"></el-input>
                    <div class="clear-icon" v-if="code !=='view'">
                      <i class="el-icon-circle-close" @click="clearFn($event,'person',k,j)"></i>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <!-- 陈列模板显示 -->
              <el-col :span="8" v-if="item.ext68=='act-detail-template-2'">
                <el-form-item label="周期" prop="ext38">
                  <el-select
                    v-model="act.ext38"
                     :disabled="code==='view'"
                    placeholder="周期"
                  >
                    <el-option
                      v-for="pItem in cycleList"
                      :key="pItem.dictValue"
                      :label="pItem.dictValue"
                      :value="pItem.dictCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 此处还差接口 -->
              <el-col :span="8" v-if="item.ext68=='act-detail-template-2'">
                <el-form-item label="阶梯" prop="ext35">
                  <el-select
                   :disabled="code==='view'"
                    v-model="act.ext35"
                    placeholder="阶梯"
                    filterable
                    remote
                    :remote-method="getDisplayLadder"
                  >
                    <el-option
                      v-for="pItem in diplayLadderList"
                      :key="pItem.ladderCode"
                      :label="pItem.ladderName"
                      :value="pItem.ladderCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="item.ext68=='act-detail-template-2'">
                <el-form-item label="家数" prop="ext36">
                  <el-input v-model="act.ext36"  :disabled="code==='view'" placeholder="家数"></el-input>
                </el-form-item>
              </el-col>

              <!-- 包量模板内容 -->
              <el-col :span="8" v-if="item.ext68=='act-detail-template-3'">
                <el-form-item label="周期" prop="ext38">
                  <el-select
                    v-model="act.ext38"
                     :disabled="code==='view'"
                    placeholder="周期"
                  >
                    <el-option
                      v-for="pItem in cycleList"
                      :key="pItem.dictValue"
                      :label="pItem.dictValue"
                      :value="pItem.dictCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="item.ext68=='act-detail-template-3'">
                <el-form-item label="阶梯" prop="ext35">
                  <el-select
                    v-model="act.ext35"
                    placeholder="阶梯"
                    filterable
                     :disabled="code==='view'"
                    remote
                    :remote-method="getPackageLadder"
                  >
                    <el-option
                      v-for="pItem in packageLadderList"
                      :key="pItem.ladderCode"
                      :label="pItem.ladderName"
                      :value="pItem.ladderCode"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="item.ext68=='act-detail-template-3'">
                <el-form-item label="家数" prop="ext36">
                  <el-input v-model="act.ext36"  :disabled="code==='view'" placeholder="家数"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="执行次数" prop="ext37">
                  <el-input
                    v-model="act.ext37"
                     :disabled="code==='view'"
                    placeholder="执行次数"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="申请金额" prop="applyAmount">
                  <el-input-number
                   :disabled="code==='view'"
                    v-model="act.applyAmount"
                    :controls="false"
                    :precision='2'
                    placeholder="申请金额"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="支付方式" prop="payType">
                  <el-select
                   :disabled="code==='view'"
                    v-model="act.payType"
                    placeholder="请选择支付方式"
                    @change="changePayType($event,k,j,act.payTypes)"
                  >
                    <el-option
                      v-for="pItem in act.payTypes"
                      :key="pItem.dictValue"
                      :label="pItem.dictKey"
                      :value="pItem.dictValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 支付方式为货补的时候显示兑付产品 -->
              <el-col :span="8" v-if="act.payType=='4'">
                <el-form-item label="兑付产品"  prop="ext9">
                  <div class="cost-select" @click="openHandleClick('product', 'redemption_product', 'materialCode', k, j,null)">
                    <el-input :readonly="true" v-model="act.ext9" placeholder="兑付产品"></el-input>
                    <div class="clear-icon" v-if="code !=='view'">
                      <i class="el-icon-circle-close" @click="clearFn($event,'product',k,j)"></i>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="act.payType=='4'">
                <el-form-item label="单价" prop="ext21">
                  <el-input
                    v-model="act.ext21"
                    disabled
                    placeholder="单价"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24" v-if="item.ext68=='act-detail-template-2' || item.ext68=='act-detail-template-3'">
                <el-form-item label="产品" prop="ext32">
                  <div class="choose-product" @click="openHandleClick('displayProduct', 'product_list', 'materialCode', k, j,null)">
                    <el-tag v-for="(pt,ptIndex) in act.productData"
                      :key="ptIndex" size="mini"
                      :closable="code==='view'?false:true"
                      style="margin:5px 10px"
                      @close="deleteChooseProduct($event,k,j,ptIndex)">
                      {{pt.materialName}}
                    </el-tag>
                  </div>

                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="item.ext68=='act-detail-template-2' || item.ext68=='act-detail-template-3'">
                <el-form-item label="产品系列" prop="ext34">
                  <div class="choose-product">
                    <el-tag v-for="(pt,ptIndex) in act.productData"
                      :key="ptIndex" size="mini"
                      :closable="code==='view'?false:true"
                      style="margin:10px"
                      @close="deleteChooseProduct($event,k,j,ptIndex)">
                      {{pt.productLevelName}}
                    </el-tag>
                  </div>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="活动执行细则" prop="ext13">
                  <el-input
                    v-model="act.ext13"
                     :disabled="code==='view'"
                    type="textarea"
                    autosize
                    placeholder="活动执行细则"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'ContainOrg',
  props: {
    code: String,
    value: Array,
    disabled: Boolean,
    time: Object,
    controlIds: Array,
    saveRef: Function,
    budgetList: Array,
    default: () => ({
      controlIds: [],
      time: {},
      budgetList: [],
      value: [],
      code: '',
    }),
  },
  data() {
    return {
      diplayLadderList: [],
      packageLadderList: [],
      cycleList: [],
      index: '',
      aIndex: '',
      chooseModule: '',
      editableTabsValue: '',
      customerList: [],
      detailVos: [],
      detailForm: {},
      detailRule: {},
      selectRow: null,
      loading: false,
      beginDate: '',
      endDate: '',
      categoriesMapKeys: new Map(),
      tabName: '',
      disableExt7: false,
      levelList: [],
    };
  },
  components: {
    SelectConfig,
  },
  watch: {
    value() {
      this.detailVos = this.value;
    },
    time(n) {
      if (n) {
        this.beginDate = n.beginDate;
        this.endDate = n.endDate;
      }
    },
    controlIds(newVal, oldVal) {
      if (newVal.length > 0) {
        this.controlIds = newVal;
      }
    },
  },
  mounted() {
    if (this.saveRef) {
      this.saveRef(this);
    }
  },
  created() {
    this.getCycleList();
    this.getPackageLadder();
    this.getDisplayLadder();
    this.getLevelList();
  },
  methods: {
    // 删除选中商品
    deleteChooseProduct(e, index, aIndex, pIndex) {
      e.stopPropagation();
      this.detailVos[index].activityInfo[aIndex].productData.splice(pIndex, 1);
      this.setChooseProduct(this.detailVos[index].activityInfo[aIndex].productData, index, aIndex);
    },
    // 获取经销商等级列表
    getLevelList() {
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'cust_ grade',
      }).then((res) => {
        if (res.success) {
          this.levelList = res.result;
        }
      });
    },

    // 获取经销商等级
    getLevelInfo(code, index, aIndex) {
      request.get('/mdm/mdmCustomerMsgExtController/getCustomerBaicInfoByCode', { customerCode: code }).then((res) => {
        if (res.success) {
          this.detailVos[index].activityInfo[aIndex].ext75 = res.result.ext1;
        }
      });
    },

    // 根据大类编码查询预算科目编码集合
    getCategoriesCode(code, index) {
      if (!code) return;
      request.post('/tpm/tpmCostTypeCategoriesExt/getBudgetSubjectsByCategoriesCode', {
        categoriesCode: code,
      }).then((res) => {
        if (res.success) {
          const tempList = [];
          const tempArr = res.result;
          this.budgetList.forEach((bItem) => {
            let flag = false;
            tempArr.forEach((tItem) => {
              if (tItem === bItem.budgetSubjectsCode) {
                flag = true;
              }
            });
            if (flag) {
              tempList.push(bItem);
            }
          });

          this.detailVos[index].budgetList = tempList;
          this.$forceUpdate();
        }
      });
    },

    // 询价
    getPrice(cCode, pCode, index, aIndex) {
      if (!cCode || !pCode) {
        this.detailVos[index].activityInfo[aIndex].ext21 = 0;
        return;
      }
      request.post('/mdm/mdmPriceSettingExtController/searchAllPrice', {
        companyCode: cCode,
        priceTypeEnum: 'P001',
        productCode: pCode,
        num: 1,
      }).then((res) => {
        if (res.success) {
          const tempRes = res.result.itemResultVoList || [];
          this.detailVos[index].activityInfo[aIndex].ext21 = (tempRes && tempRes.length > 0) ? (tempRes[0].price || 0) : 0;
        }
      });
    },

    // 获取包量阶梯
    getPackageLadder(val) {
      request.get('/cps/v1/agreement/agreementLadder/findByConditions', {
        pageSize: 5000,
        ladderType: 'quantify',
        ladderName: val,
      }).then((res) => {
        if (res.success) {
          this.packageLadderList = res.result.records;
        }
      });
    },

    // 获取陈列阶梯
    getDisplayLadder(val) {
      request.get('/cps/v1/agreement/agreementLadder/findByConditions', {
        pageSize: 5000,
        ladderType: 'display',
        ladderName: val,
      }).then((res) => {
        if (res.success) {
          this.diplayLadderList = res.result.records;
        }
      });
    },

    // 更改支付类型
    changePayType(val, index, aIndex, list) {
      this.detailVos[index].activityInfo[aIndex].ext9 = null;
      this.detailVos[index].activityInfo[aIndex].ext8 = null;
      this.detailVos[index].activityInfo[aIndex].ext21 = null;
      if (val) {
        for (const item of list) {
          if (item.dictCode === val) {
            this.detailVos[index].activityInfo[aIndex].payTypeName = item.dictValue;
          }
        }
      } else {
        this.detailVos[index].activityInfo[aIndex].payTypeName = null;
        this.detailVos[index].activityInfo[aIndex].ext9 = null;
        this.detailVos[index].activityInfo[aIndex].ext8 = null;
        this.detailVos[index].activityInfo[aIndex].ext21 = null;
      }
    },

    // 打开点击弹窗
    openHandleClick(module, list, key, index, aIndex, paramData) {
      console.log(module);
      if (this.code === 'view') {
        return;
      }
      this.index = index;
      this.aIndex = aIndex;
      this.chooseModule = module;
      let params = {};
      if (module === 'customer' || module === 'org') {
        params = {
          functionCode: list,
          data: [],
          idKey: key,
          paramData: {
            enableStatus: '009',
          },
        };
      } else {
        params = {
          functionCode: list,
          data: [],
          idKey: key,
          paramData,
        };
      }
      this.$refs.selectConfig.openSelectModal(params);
    },

    // 获取周期
    getCycleList() {
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'tpm_cycle_001',
      }).then((res) => {
        if (res.success) {
          this.cycleList = res.result;
        }
      });
    },

    // 清除数据
    clearFn(e, code, index, aIndex) {
      e.stopPropagation();
      if (code === 'customer') {
        this.detailVos[index].activityInfo[aIndex].ext7 = null;
        this.detailVos[index].activityInfo[aIndex].ext6 = null;
        this.detailVos[index].activityInfo[aIndex].payTypes = [];
        this.detailVos[index].activityInfo[aIndex].ext67 = null;
        this.detailVos[index].activityInfo[aIndex].ext66 = null;
        this.detailVos[index].activityInfo[aIndex].ext75 = null;
      } else if (code === 'org') {
        this.detailVos[index].activityInfo[aIndex].ext5 = null;
        this.detailVos[index].activityInfo[aIndex].ext4 = null;
        this.detailVos[index].activityInfo[aIndex].payTypes = this.detailVos[index].payTypes;
        this.detailVos[index].activityInfo[aIndex].ext75 = null;
      } else if (code === 'person') {
        this.detailVos[index].activityInfo[aIndex].ext40 = null;
        this.detailVos[index].activityInfo[aIndex].ext39 = null;
        this.detailVos[index].activityInfo[aIndex].ext69 = null;
        this.detailVos[index].activityInfo[aIndex].ext70 = null;
        this.detailVos[index].activityInfo[aIndex].ext64 = null;
        this.detailVos[index].activityInfo[aIndex].ext74 = null;
      } else if (code === 'product') {
        this.detailVos[index].activityInfo[aIndex].ext8 = null;
        this.detailVos[index].activityInfo[aIndex].ext9 = null;
        this.detailVos[index].activityInfo[aIndex].ext21 = 0;
      } else if (code === 'fine') { // 活动细类
        this.detailVos[index].fineCode = null;
        this.detailVos[index].fineName = null;
        this.detailVos[index].activityInfo = [];
      } else if (code === 'dealer') {
        this.detailVos[index].activityInfo[aIndex].ext66 = null;
        this.detailVos[index].activityInfo[aIndex].ext67 = null;
      } else if (code === 'categories') {
        this.detailVos[index].categoriesName = null;
        this.detailVos[index].categoriesCode = null;
        this.detailVos[index].fineCode = null;
        this.detailVos[index].fineName = null;
        this.detailVos[index].activityInfo = [];
      }
    },

    // 获取弹窗选中数据
    onGetSelect(data) {
      const { index, aIndex } = this;
      if (this.chooseModule === 'org') {
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext4 = data[0].orgCode; // 受益组织编码
          this.detailVos[index].activityInfo[aIndex].ext5 = data[0].orgName; // 受益组织名称
          // 如果受益组织有值，去处理支付方式，判断支付方式中是否有现金，控制其只能选择现金
          const payTypeArr = this.detailVos[index].activityInfo[aIndex].payTypes;
          const tempArr = [];
          for (const item of payTypeArr) {
            if (item.dictValue === '1') {
              tempArr.push(item);
            }
          }
          this.detailVos[index].activityInfo[aIndex].payTypes = tempArr;
        } else {
          this.detailVos[index].activityInfo[aIndex].ext5 = null;
          this.detailVos[index].activityInfo[aIndex].ext4 = null;
          this.detailVos[index].activityInfo[aIndex].payTypes = this.detailVos[index].payTypes;
        }
        // 组织和经销商二选一，故选择了组织要清空经销商
        this.detailVos[index].activityInfo[aIndex].ext6 = null;
        this.detailVos[index].activityInfo[aIndex].ext7 = null;
        this.detailVos[index].activityInfo[aIndex].ext66 = null;
        this.detailVos[index].activityInfo[aIndex].ext67 = null;
        this.detailVos[index].activityInfo[aIndex].ext75 = null;
      } else if (this.chooseModule === 'customer') {
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext7 = data[0].customerName; // 受益经销商名称
          this.detailVos[index].activityInfo[aIndex].ext6 = data[0].customerCode; // 受益经销商编码
          this.detailVos[index].activityInfo[aIndex].payTypes = this.detailVos[index].payTypes;
          this.getLevelInfo(data[0].customerCode, index, aIndex);
        } else {
          this.detailVos[index].activityInfo[aIndex].ext7 = null;
          this.detailVos[index].activityInfo[aIndex].ext6 = null;
          this.detailVos[index].activityInfo[aIndex].payTypes = [];
          this.detailVos[aIndex].activityInfo[dIndex].ext75 = null;
        }
        // 组织和经销商二选一，故选择了经销商要清空组织
        this.detailVos[index].activityInfo[aIndex].ext67 = null;
        this.detailVos[index].activityInfo[aIndex].ext66 = null;
        this.detailVos[index].activityInfo[aIndex].ext4 = null;
        this.detailVos[index].activityInfo[aIndex].ext5 = null;
      } else if (this.chooseModule === 'product') { // 兑付产品
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext8 = data[0].materialCode;
          this.detailVos[index].activityInfo[aIndex].ext9 = data[0].materialName;
        } else {
          this.detailVos[index].activityInfo[aIndex].ext8 = null;
          this.detailVos[index].activityInfo[aIndex].ext9 = null;
        }
        this.getPrice(this.detailVos[index].activityInfo[aIndex].ext2, this.detailVos[index].activityInfo[aIndex].ext8, index, aIndex);
      } else if (this.chooseModule === 'person') {
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext40 = data[0].fullName;
          this.detailVos[index].activityInfo[aIndex].ext39 = data[0].userName;
          this.detailVos[index].activityInfo[aIndex].ext69 = data[0].positionCode;
          this.detailVos[index].activityInfo[aIndex].ext70 = data[0].positionName;
          this.detailVos[index].activityInfo[aIndex].ext64 = data[0].orgCode; // 负责人组织编码
          this.detailVos[index].activityInfo[aIndex].ext74 = data[0].orgName; // 负责人组织
        } else {
          this.detailVos[index].activityInfo[aIndex].ext40 = null;
          this.detailVos[index].activityInfo[aIndex].ext39 = null;
          this.detailVos[index].activityInfo[aIndex].ext69 = null;
          this.detailVos[index].activityInfo[aIndex].ext70 = null;
          this.detailVos[index].activityInfo[aIndex].ext64 = null; // 负责人组织编码
          this.detailVos[index].activityInfo[aIndex].ext74 = null; // 负责人组织
        }
      } else if (this.chooseModule === 'displayProduct') { // 陈列或包量产品
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].productData = data;
        } else {
          this.detailVos[index].activityInfo[aIndex].productData = [];
        }
        this.setChooseProduct(this.detailVos[index].activityInfo[aIndex].productData, index, aIndex);
      } else if (this.chooseModule === 'fine') {
        if (data.length > 0) {
          this.detailVos[index].fineCode = data[0].fineCode;
          this.detailVos[index].ext68 = data[0].costFormCode;
          this.detailVos[index].fineName = data[0].fineName;
          this.detailVos[index].payTypes = data[0].payTypes;
          this.detailVos[index].ext71 = data[0].isSendSfa; // 是否推送sfa
          this.detailVos[index].ext72 = data[0].isSupervise; // 是否需要督查
          this.detailVos[index].ext63 = data[0].ext10; // 活动执行表单
          this.detailVos[index].ext73 = data[0].ext1; // 是否沿用执行数据
        } else {
          this.detailVos[index].fineCode = null;
          this.detailVos[index].fineName = null;
          this.detailVos[index].ext68 = null;
          this.detailVos[index].payTypes = [];
          this.detailVos[index].ext71 = null;
          this.detailVos[index].ext72 = null;
          this.detailVos[index].ext63 = null; // 活动执行表单
          this.detailVos[index].ext73 = null; // 是否沿用执行数据
        }
        this.detailVos[index].activityInfo = [];
      } else if (this.chooseModule === 'dealer') {
        if (data.length > 0) {
          this.detailVos[index].activityInfo[aIndex].ext66 = data[0].orgCode;
          this.detailVos[index].activityInfo[aIndex].ext67 = data[0].orgName;
        } else {
          this.detailVos[index].activityInfo[aIndex].ext66 = null;
          this.detailVos[index].activityInfo[aIndex].ext67 = null;
        }
      } else if (this.chooseModule === 'categories') {
        if (data.length > 0) {
          this.getCategoriesCode(data[0].categoriesCode, index);
          this.detailVos[index].categoriesName = data[0].categoriesName;
          this.detailVos[index].categoriesCode = data[0].categoriesCode;
        } else {
          this.detailVos[index].categoriesName = null;
          this.detailVos[index].categoriesCode = null;
        }
        this.detailVos[index].fineCode = null;
        this.detailVos[index].fineName = null;
        this.detailVos[index].activityInfo = [];
      }
      this.$emit('input', this.detailVos);
    },
    // 设置选中的商品
    setChooseProduct(data, index, aIndex) {
      const productCode = []; const productName = []; const levelCode = []; const levelName = [];
      for (const item of data) {
        productCode.push(item.materialCode);
        productName.push(item.materialName);
        levelCode.push(item.productLevelCode);
        levelName.push(item.productLevelName);
      }
      this.detailVos[index].activityInfo[aIndex].ext31 = productCode.toString();
      this.detailVos[index].activityInfo[aIndex].ext32 = productName.toString();
      this.detailVos[index].activityInfo[aIndex].ext33 = levelCode.toString();
      this.detailVos[index].activityInfo[aIndex].ext34 = levelName.toString();
    },
    // 改变关联预算
    changeFeeBudget(val, aIndex, dIndex) {
      if (val) {
        for (const item of this.budgetList) {
          if (item.feeBudgetCode === val) {
            this.detailVos[aIndex].activityInfo[dIndex].ext67 = null; // 清空经销商组织名称
            this.detailVos[aIndex].activityInfo[dIndex].ext66 = null; // 清空经销商组织

            this.detailVos[aIndex].activityInfo[dIndex].ext62 = item.feeBudgetType; // 费用预算类型
            this.detailVos[aIndex].activityInfo[dIndex].ext3 = item.ext8; // 公司主体名称
            this.detailVos[aIndex].activityInfo[dIndex].ext2 = item.ext7; // 公司主体编码
            this.detailVos[aIndex].activityInfo[dIndex].budgetSubjectsCode = item.budgetSubjectsCode; // 预算科目编码
            this.detailVos[aIndex].activityInfo[dIndex].ext19 = item.budgetSubjectsName; // 预算科目名称
            this.detailVos[aIndex].activityInfo[dIndex].orgCode = item.orgCode; // 预算科目名称
            this.detailVos[aIndex].activityInfo[dIndex].orgName = item.orgName; // 预算科目名称
            // 当前有活动明细关联预算的经销商字段有值，直接将预算经销商写入受益经销商，且不允许选择受益组织
            if (item.customerCode) {
              this.detailVos[aIndex].activityInfo[dIndex].ext6 = item.customerCode; // 受益经销商编码
              this.detailVos[aIndex].activityInfo[dIndex].ext7 = item.customerName; // 受益经销商名称
              this.disableExt7 = true;
              this.getLevelInfo(item.customerCode, aIndex, dIndex);
            } else {
              this.detailVos[aIndex].activityInfo[dIndex].ext6 = null; // 受益经销商编码
              this.detailVos[aIndex].activityInfo[dIndex].ext7 = null; // 受益经销商名称
              this.detailVos[aIndex].activityInfo[dIndex].ext75 = null;
              this.disableExt7 = false;
            }
          }
        }
      } else {
        this.detailVos[aIndex].activityInfo[dIndex].ext61 = null;
        this.detailVos[aIndex].activityInfo[dIndex].ext62 = null;
        this.detailVos[aIndex].activityInfo[dIndex].ext3 = null;
        this.detailVos[aIndex].activityInfo[dIndex].ext2 = null;
        this.detailVos[aIndex].activityInfo[dIndex].budgetSubjectsCode = null;
        this.detailVos[aIndex].activityInfo[dIndex].ext19 = null;
      }
      this.getPrice(this.detailVos[aIndex].activityInfo[dIndex].ext2, this.detailVos[aIndex].activityInfo[dIndex].ext8, aIndex, dIndex);
      this.$emit('input', this.detailVos);
    },

    // 更改执行时间
    changeExecuteTime(val, index, aIndex) {
      if (val) {
        const [startTime, endTime] = val;
        const [startDate, startSeconde] = startTime.split(' ');
        const [endDate, endSeconde] = endTime.split(' ');
        this.detailVos[index].activityInfo[aIndex].executeBeginDate = startDate;
        this.detailVos[index].activityInfo[aIndex].executeBeginDateSecond = startSeconde;
        this.detailVos[index].activityInfo[aIndex].executeEndDate = endDate;
        this.detailVos[index].activityInfo[aIndex].executeEndDateSecond = endSeconde;
      } else {
        this.detailVos[index].activityInfo[aIndex].executeBeginDate = null;
        this.detailVos[index].activityInfo[aIndex].executeBeginDateSecond = null;
        this.detailVos[index].activityInfo[aIndex].executeEndDate = null;
        this.detailVos[index].activityInfo[aIndex].executeEndDateSecond = null;
      }
    },

    // 删除活动
    deleteActivity(index) {
      this.$confirm('确定要删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.detailVos.splice(index, 1);
        this.$emit('input', this.detailVos);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      });
    },

    // 新增活动类型
    addActivity() {
      const { beginDate, endDate } = this.time;
      if (this.time && (!beginDate || !endDate)) {
        this.$message.error('请先选择活动时间！');
      } else if (
        !this.controlIds
        || (this.controlIds && this.controlIds.length === 0)
      ) {
        this.$message.error('请先选择预算费用！');
      } else {
        this.detailVos.push({
          categoriesName: '',
          categoriesCode: '',
          fineCode: '',
          fineName: '',
          activityInfo: [],
          ext68: null, // 表单号
          payTypes: [],
          editableTabsValue: null, // 激活的选项
          ext71: null, // 是否推送sfa
          ext72: null, // 是否需要督查
          ext63: null,
          ext73: null,
          budgetList: [],
        });
        this.$emit('input', this.detailVos);
      }
    },

    // 新增明细
    addDetail(index, item) {
      if (item.fineCode) {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.detailVos[index].editableTabsValue = `activity${index}-1`;
        this.detailVos[index].activityInfo.push({
          productData: [], // 选择的商品
          ext61: null,
          ext62: null, // 费用预算类型
          payTypes: [],
          orgCode: null,
          orgName: null,
          feeBudgetCode: null,
          feeBudgetTypeName: null,
          ext3: null, // 公司主体名称
          ext2: null, // 公司主体编码
          budgetSubjectsCode: null,
          ext19: null, // 预算科目名称
          actDetailCode: null,
          ext1: null, // 活动明细名称
          executeBeginDate: null,
          executeBeginDateSecond: null,
          executeEndDate: null,
          executeEndDateSecond: null,
          executeTime: null,
          ext6: null, // 受益经销商编码
          ext7: null, // 受益经销商名称
          ext66: null, // 经销商组织编码
          ext67: null, // 经销商组织名称
          ext4: null, // 受益组织编码
          ext5: null, // 受益组织名称
          createOrgCode: userInfo.orgCode || null, // 责任组织编码
          createOrgName: userInfo.orgName || null, // 责任组织名称
          ext40: null, // 责任人名称
          ext39: null, // 责任人编码
          ext69: null, // 职位编码
          ext70: null, // 职位名称
          applyAmount: null, // 申请金额
          ext8: null, // 兑付产品编码
          ext9: null, // 兑付产品名称
          ext21: null, // 单价
          payType: null, // 支付方式
          ext37: null, // 执行次数
          ext38: null, // 陈列周期
          ext31: null, // 陈列产品编码
          ext32: null, // 产品名称
          ext33: null, // 产品系列编码
          ext34: null, // 产品系列描述
          ext35: null, // 阶梯
          ext36: null, // 家数
          ext64: null, // 负责人组织编码
          ext74: null, // 负责人组织
          ext75: null, // 经销商等级
        });

        /* eslint-disable */
      this.detailVos[index].activityInfo.forEach((aItem,a) => {
        aItem.payTypes =this.detailVos[index].payTypes;
        aItem.tabValue = `activity${index}-${a+1}`;
      });
      } else {
        this.$message.error('请先选择活动细类');
      }
      this.$emit('input', this.detailVos);
    },

    // 保留已选大类
    async reset(ids) {
      if (ids && ids.length) {} else {
        this.clear();
      }
    },

    // 清空数据
    clear(){
      this.detailVos = [];
       this.$emit('input', this.detailVos);
    },

    // 移出tabs
    removeTab(targetName, index) {
       this.$confirm('确定要删除吗, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         let tabs =  this.detailVos[index].activityInfo;
            let activeName = this.detailVos[index].editableTabsValue;
            if (activeName === targetName) {
              tabs.forEach((tab, index) => {
                if (tab.tabValue === targetName) {
                  let nextTab = tabs[index + 1] || tabs[index - 1];
                  if (nextTab) {
                    activeName = nextTab.tabValue;
                  }
                }
              });
            }
            this.detailVos[index].activityInfo = tabs.filter(tab => tab.tabValue !== targetName);
            if(this.detailVos[index].activityInfo.length===1){
                this.detailVos[index].editableTabsValue = this.detailVos[index].activityInfo[0].tabValue
            }else{
              this.detailVos[index].editableTabsValue = activeName;
            }
            this.$emit('input', this.detailVos);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },


    // 新增预算信息
    addTab() {
      const { beginDate, endDate } = this.time;
      if (this.time && (!beginDate || !endDate)) {
        this.$message.error('请先选择活动时间！');
      } else if (
        !this.controlIds
        || (this.controlIds && this.controlIds.length === 0)
      ) {
        this.$message.error('请先选择预算费用！');
      } else {
        const _data = Array.from(this.categoriesMapKeys.keys()).map((v) => ({
          categoriesCode: v,
        }));
        const params = {
          functionCode: 'tpm_project_categories_list',
          data: _data,
          idKey: 'categoriesCode',
          paramData: {
            enableStatus: '009',
            controlIds: this.controlIds,
            actType: 'promotion',
          },
        };
        this.$refs.selectConfig.openSelectModal(params);
      }
    }
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;
}
.list-wrap {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 20px;

}
.cost-select {
  position: relative;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
.choose-product{
  width: 100%;
  min-height: 32px;
  border:1px solid #e4e7ed;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  padding: 6px;
}
</style>
